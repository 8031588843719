<template>
	<div>
		<div class="title">
			<div class="title_jl">在线简历</div>
			<div class="title_yl pointer" @click="previewClick">预览</div>
		</div>
		<div class="active">
			<div class="activeText" v-if="Person">
				<div v-if="Person.detail" class="activeTextr">
					<div class="activeName">
						<div class="nameleft">{{ Person.name }}</div>
						<img class="nameimg" :src="Person.avatar_url" v-if="Person.avatar_url" />
						<img class="nameimg" src="../../../assets/login/922028.png" v-else />
					</div>
					<div class="activeExperience">
						<div class="active_jingyan">
							<i class="el-icon-suitcase experience_icon"></i>
							<div class="experience">{{ Person.other_info.work_year }}年经验</div>
						</div>
						<div class="active_xt"></div>
						<div class="active_jingyan">
							<i class="el-icon-suitcase experience_icon"></i>
							<div class="experience" v-if="Person.school.length > 0">
								{{ Person.school[0].education }}
							</div>
							<div class="experience" v-else>无</div>
						</div>
						<div class="active_xt" v-if="Person.other_info.job_status"></div>
						<div class="active_jingyan" v-if="Person.other_info.job_status">
							<i class="el-icon-suitcase experience_icon"></i>
							<div class="experience">{{ Person.other_info.job_status }}</div>
						</div>
					</div>
					<div class="activeExperience" style="margin-top: 10px">
						<div class="active_jingyan">
							<i class="el-icon-suitcase experience_icon"></i>
							<div class="experience">{{ Person.other_info.age }}岁</div>
						</div>
						<div class="active_xt"></div>
						<div class="active_jingyan">
							<i class="el-icon-suitcase experience_icon"></i>
							<div class="experience">{{ Person.phone }}</div>
						</div>
					</div>
				</div>
				<div v-else class="addition activeTextr">
					<div class="addition_top">
						<div class="top_left">基本信息</div>
						<div class="top_right pointer" @click="navTo('/my-resume/basic')">
							<div class="">
								<img src="../../../assets/glxt/10.png" alt="" />
							</div>
							<div class="right_tianjia">添加</div>
						</div>
					</div>
					<div class="addition_up">展示您的基本信息，获得更多关注</div>
				</div>
				<div class="addition activeTextr">
					<div class="addition_top">
						<div class="top_left">个人优势</div>
						<div class="top_right pointer" @click="navTo('/my-resume/advantage', 1)"
							v-if="!Person.detail || !Person.detail.advantage">
							<div class="">
								<img src="../../../assets/glxt/10.png" alt="" />
							</div>
							<div class="right_tianjia">添加</div>
						</div>
					</div>
					<div class="addition_up" v-if="Person.detail">
						{{
              Person.detail.advantage
                ? Person.detail.advantage
                : "展示您的个人优势，获得更多关注"
            }}
					</div>
				</div>
				<!-- 求职意向 -->
				<div v-if="job_intention.length == 0 && job_intentionShow == false" class="addition activeTextr">
					<div class="addition_top">
						<div class="top_left">求职意向</div>
						<div class="top_right pointer" @click="job_intentionShow = true">
							<div class="">
								<img src="../../../assets/glxt/10.png" alt="" />
							</div>
							<div class="right_tianjia">添加</div>
						</div>
					</div>
					<div class="addition_up">
						当前暂时无求职意向，添加后将给您推荐匹配度更高的职位
					</div>
				</div>
				<!-- 求职意向 -->
				<div v-if="job_intention.length > 0 && job_intentionShow == false" class="addition activeTextr">
					<div class="addition_top">
						<div class="top_left">求职意向</div>
						<div class="top_right pointer" @click="job_intentionShow = true">
							<div class="">
								<img src="../../../assets/glxt/9.png" alt="" />
							</div>
							<div class="right_tianjia">修改</div>
						</div>
					</div>
					<div class="addition_ups" v-for="item in job_intention" :key="item.id">
						{{ item.one_position }} ｜ {{ item.salary_min }}-{{
              item.salary_max
            }}
						｜ {{ item.position_name }} ｜ {{ item.province }}
					</div>
				</div>
				<!-- 编辑求职意向 -->
				<div v-if="job_intentionShow" class="redact">
					<div class="active_bianji">
						<div class="bianju_qzyx">编辑求职意向</div>
						<div v-for="(item, index) in query" :key="index">
							<div class="bianju_qzxz">
								<div class="qzxz_left">
									<div class="left_qwzw">期望职位</div>
									<el-cascader style="margin-top: 12px" v-model="item.category_ids"
										:options="positionList" :show-all-levels="false" placeholder="请选择期望职位" :props="{
                      value: 'id',
                      label: 'title',
                      children: 'childs',
                    }" class="el-left_xzqw" @change="handleChange(index)" ref="category">
									</el-cascader>
								</div>
								<div class="qzxz_right">
									<div class="right_xzyq">薪资要求</div>
									<div style="display: flex; align-items: center">
										<el-select v-model="item.salary_min_value" placeholder="请选择薪酬范围"
											style="margin-top: 12px" class="el-xzyq_fw" ref="price"
											@change="changePrice(index)">
											<el-option v-for="itemOne in priceOptions" :key="itemOne.value"
												:label="itemOne.label" :value="itemOne.value">
											</el-option>
										</el-select>
										<div class="right_xinzi">至</div>
										<el-select v-model="item.salary_max_value" placeholder="请选择薪酬范围"
											style="margin-top: 12px" class="el-xzyq_fw" @change="changePriceOne(index)">
											<el-option v-for="itemOne in priceOptionsOne" :key="itemOne.value2"
												:label="itemOne.label" :value="itemOne.value">
											</el-option>
										</el-select>
									</div>
								</div>
							</div>
							<div class="bianju_qzxz">
								<div class="qzxz_left">
									<div class="left_qwzw">工作地区</div>
									<el-select v-model="item.province_id" class="el-left_xzqw" style="margin-top: 12px"
										@change="handleChangeAddress(index)" placeholder="请选择地址">
										<el-option v-for="itemOne in addressList" :key="itemOne.id"
											:label="itemOne.label" :value="itemOne.id">
										</el-option>
									</el-select>
								</div>
								<div class="qzxz_right" style="display: flex; align-items: center">
									<div class="el-right_qz pointer" v-for="(itemOne, indexOne) in list" :key="indexOne"
										:class="{ right_qs: query[index].type == itemOne.type }"
										@click="query[index].type = itemOne.type">
										{{ itemOne.name }}
									</div>
								</div>
							</div>
							<div class="bianju_qzxz">
								<div></div>
								<div class="qzxz_right" style="margin-top: 28px; display: flex; align-items: center">
									<div class="right_qx pointer" @click="deleteQuery(index)">删除</div>
								</div>
							</div>
						</div>
						<div class="row-end">
							<div class="bg-blue flex-center el-add-bianji pointer" @click="addcertificatesClick">
								继续添加
							</div>
							<div class="el-right_wc bg-blue flex-center pointer" @click="submitClick">
								完成
							</div>
						</div>
					</div>
				</div>
				<!-- 工作经历 -->
				<div class="addition activeTextr">
					<div class="addition_top">
						<div class="top_left">工作经历</div>
						<div class="top_right pointer" @click="navTo('/my-resume/experience', 1)"
							v-if="Person.work.length == 0">
							<div class="">
								<img src="../../../assets/glxt/10.png" alt="" />
							</div>
							<div class="right_tianjia">添加</div>
						</div>
					</div>
					<div class="addition_up" v-if="Person.work.length == 0">
						添加您的过往工作经历，展示您的工作能力与成就
					</div>
					<div class="addition_ups-one" v-else>
						<div v-for="item in Person.work" :key="item.id" class="addition_ups-one-item">
							<div class="row-center font-weight-bolder text-main addition_ups-one-heard">
								<div>{{ item.company_name }}</div>
								<div>{{ item.job_times }}</div>
							</div>
							<div class="font-weight-bolder text-main addition_ups-one-title">
								{{ item.work_name }}
							</div>
							<div class="row text-main font-normal">
								<div>内容：</div>
								<div class="workContent">
									<div v-for="(workContent, index) in item.splitAdd" :key="index">
										{{ workContent }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 教育经历 -->
				<div class="addition activeTextr">
					<div class="addition_top">
						<div class="top_left">教育经历</div>
						<div class="top_right pointer" @click="navTo('/my-resume/educational', 1)"
							v-if="Person.school.length == 0">
							<div class="">
								<img src="../../../assets/glxt/10.png" alt="" />
							</div>
							<div class="right_tianjia">添加</div>
						</div>
					</div>
					<div class="addition_up" v-if="Person.school.length == 0">
						添加您的教育经历，企业更加了解您
					</div>
					<div class="addition_up-one" v-else>
						<div class="row-center font-normal text-main">
							{{ Person.school[0].school_name }} ｜ {{ Person.school[0].school_times }}
						</div>
						<div class="font-normal text-main addition_up-one-title">
							{{ Person.school[0].major }} ｜ {{ Person.school[0].education }}
						</div>
						<div class="row addition_up-one-bottom font-normal text-main">
							<div>在校经历：</div>
							<div class="workContent-one">
								<div v-for="(workContent, index) in experience_one" :key="index">
									{{ workContent }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 资格证书 -->
				<div class="addition activeTextr">
					<div class="addition_top">
						<div class="top_left">资格证书</div>
						<div class="top_right pointer" @click="navTo('/my-resume/certificate', 1)"
							v-if="!Person.certificate">
							<div class="">
								<img src="../../../assets/glxt/10.png" alt="" />
							</div>
							<div class="right_tianjia">添加</div>
						</div>
					</div>
					<div v-if="Person.certificate">
						<div class="addition_up" v-for="(item, index) in Person.certificate.certificates" :key="index">
							{{ item }}
						</div>
					</div>
					<div class="addition_up" v-if="Person.certificate && Person.certificate.certificates.length == 0">
						暂未添加
					</div>
				</div>
			</div>
		</div>
		<mask-preview ref="maskPreview"></mask-preview>
	</div>
</template>

<script>
	import api from "../../../api/user";
	import apiTool from "../../../api/tool";
	import maskPreview from "../components/preview.vue";
	export default {
		components: {
			maskPreview
		},

		data() {
			return {
				Person: null,
				job_intentionShow: false,
				job_intention: [],
				query: [{
					id: 0,
					type: 1,
					position_id: "", //二级职位id
					position_name: "", //二级职位名称
					one_position_id: "", //一级职位id
					one_position: "", //一级职位名称
					salary_min: "", //最低薪资
					salary_max: "", //最高薪资
					province: "", //省份
					province_id: "", //省份id
					category_ids: [],
					address_value: "",
					salary_max_value: "",
					salary_min_value: "",
				}, ],
				salary_min: "",
				salary_max: "",
				positionList: [],
				options: [{
						value: "zhinan",
						label: "生产管理",
						children: [{
								value: "shejiyuanze",
								label: "厂长/副厂长",
							},
							{
								value: "shengchang",
								label: "生产经理",
							},
						],
					},
					{
						value: "gongcs",
						label: "工程师/技术员",
						children: [{
							value: "shejiyuanze",
							label: "工程师",
						}, ],
					},
				],
				list: [{
						type: 1,
						name: "全职",
					},
					{
						type: 2,
						name: "实习",
					},
				],
				addressList: [],
				addressValue: [],
				categoryIds: [],
				experience_one: [],
				priceOptions: [],
				priceOptionsOne: [],
				disabled: true,
				disabledShow: false,
			};
		},

		created() {
			if (this.$route.query.id) {
				this.job_intentionShow = true;
			}
			this.getResume();
			this.toolist();
			this.addressClick();
			let priceListOne = [];
			for (let index = 0; index < 29; index++) {
				priceListOne.push({
					value: index + 1,
					label: index + 1 + "k",
				});
			}
			let priceListTwo = [];
			for (let index = 30; index <= 100; index++) {
				if (index % 5 == 0) {
					priceListTwo.push({
						value: index,
						label: index + "k",
					});
				}
			}

			this.priceOptions = [...priceListOne, ...priceListTwo];
			this.priceOptionsOne = [...priceListOne, ...priceListTwo];
		},

		mounted() {},

		methods: {
			deleteQuery(index) {
				if (this.query.length > 1) {
					this.query.splice(index, 1);
				} else {
					this.job_intentionShow = false;
				}
			},
			addcertificatesClick() {
				let arr = this.query.map((item) => {
					return {
						id: item.id,
						type: item.type,
						position_id: item.position_id, //二级职位id
						position_name: item.position_name, //二级职位名称
						one_position_id: item.one_position_id, //一级职位id
						one_position: item.one_position, //一级职位名称
						salary_min: item.salary_min, //最低薪资
						salary_max: item.salary_max, //最高薪资
						province: item.province, //省份
						province_id: item.province_id, //省份id
					};
				});
				for (let i = 0; i < arr.length; i++) {
					if(parseInt(arr[i]['salary_min']) > parseInt(arr[i]['salary_max'])){
						return this.$util.msg("最低薪资大于最高薪资",'error');
					}
				}
				
				let obj = {
					id: 0,
					type: 1,
					position_id: "", //二级职位id
					position_name: "", //二级职位名称
					one_position_id: "", //一级职位id
					one_position: "", //一级职位名称
					salary_min: "", //最低薪资
					salary_max: "", //最高薪资
					province: "", //省份
					province_id: "", //省份id
					category_ids: [],
					address_value: "",
					salary_max_value: "",
					salary_min_value: "",
				};
				this.query.push(obj);
			},
			previewClick() {
				if (!this.Person.detail || this.Person.detail.completeness < 90) {
					this.$util.msg("你的简历完善度小于90%，请继续完善简历", "warning");
					return;
				}
				this.$refs.maskPreview.show();
			},
			navTo(path, id) {
				this.$router.push({
					path,
					query: {
						id
					},
				});
			},
			// 完成
			submitClick() {
				let arr = this.query.map((item) => {
					return {
						id: item.id,
						type: item.type,
						position_id: item.position_id, //二级职位id
						position_name: item.position_name, //二级职位名称
						one_position_id: item.one_position_id, //一级职位id
						one_position: item.one_position, //一级职位名称
						salary_min: item.salary_min, //最低薪资
						salary_max: item.salary_max, //最高薪资
						province: item.province, //省份
						province_id: item.province_id, //省份id
					};
				});
				// 判断是否为空
				for (let i = 0; i < arr.length; i++) {
					if(parseInt(arr[i]['salary_min']) > parseInt(arr[i]['salary_max'])){
						return this.$util.msg("最低薪资大于最高薪资",'error');
					}
					if(!arr[i]['one_position'] || !arr[i]['one_position_id'] || !arr[i]['position_id'] || !arr[i]['position_name'] || !arr[i]['salary_max'] || !arr[i]['salary_min'] || !arr[i]['province'] || !arr[i]['province_id']){
						return this.$util.msg("求职意向需填写完整！",'error');
					}
				}
				let query = {
					job_intention: arr,
				};
				api.setResume(query).then((res) => {
					if (res.code == 200) {
						this.$util.msg("编辑成功");
						this.getResume();
						this.job_intentionShow = false;
					}
				});
			},
			// 最低薪资
			changePrice(index) {
				let salary_min = this.priceOptions.filter((item) => {
					return item.value == this.query[index].salary_min_value;
				});
				this.query[index].salary_min = salary_min[0].label;
				this.$forceUpdate();
			},
			// 最高薪资
			changePriceOne(index) {
				let salary_max = this.priceOptions.filter((item) => {
					return item.value == this.query[index].salary_max_value;
				});
				this.query[index].salary_max = salary_max[0].label;
				this.$forceUpdate();
			},
			// 获取简历信息
			getResume() {
				this.addressValue = [];
				this.categoryIds = [];
				const loading = this.$loading({
					lock: true,
					text: "加载中...",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				api.getResume().then((res) => {
					if (res.code == 200) {
						if (res.data.work.length > 0) {
							res.data.work.forEach((item) => {
								if (item.to_hide == 1) {
									item.to_hide = true;
								} else {
									item.to_hide = false;
								}
								if (item.work_content) {
									item.splitAdd = item.work_content.split("\n");
								}
							});
						}
						if (res.data.school.length > 0) {
							if (res.data.school[0].experience) {
								this.experience_one = res.data.school[0].experience.split("\n");
							}
						}
						this.Person = res.data;
						let job_intention = res.data.job_intention;
						this.job_intention = job_intention;

						if (job_intention.length > 0) {
							for (let index = 0; index < job_intention.length; index++) {
								this.query[index] = job_intention[index];
								let category_ids = [];
								category_ids.push(job_intention[index].one_position_id);
								category_ids.push(job_intention[index].position_id);
								this.query[index].category_ids = category_ids;

								if (job_intention[index].salary_min) {
									let salary_min = this.priceOptions.filter((item) => {
										return item.label == job_intention[index].salary_min;
									});
									this.query[index].salary_min_value = salary_min[0].value;
								}
								if (job_intention[index].salary_max) {
									let salary_max = this.priceOptions.filter((item) => {
										return item.label == job_intention[index].salary_max;
									});
									this.query[index].salary_max_value = salary_max[0].value;
								}
							}
						}
					}
					loading.close();
				});
			},
			// 获取配置
			toolist() {
				apiTool.positionCategory().then((res) => {
					if (res.code == 200) {
						this.positionList = res.data.data;
					}
				});
			},
			// 获取城市地址
			addressClick() {
				apiTool.getAddress().then((res) => {
					let region = res.data.children;
					region.forEach((element) => {
						element.children = "";
					});
					this.addressList = region;
				});
			},
			handleChange(index) {
				this.query[index].position_id = this.query[index].category_ids[1];
				this.query[index].one_position_id = this.query[index].category_ids[0];
				let arr1 = this.positionList.filter((item) => {
					return item.id == this.query[index].category_ids[0];
				});
				this.query[index].one_position = arr1[0].title;
				let arr2 = arr1[0].childs.filter((item) => {
					return item.id == this.query[index].category_ids[1];
				});
				this.query[index].position_name = arr2[0].title;
			},
			// 选择地址
			handleChangeAddress(index) {
				let arr1 = this.addressList.filter((item) => {
					return this.query[index].province_id == item.id;
				});
				this.query[index].province = arr1[0].label;
			},
		},
	};
</script>
<style lang="less" scoped>
	/deep/.el-input__inner {
		font-size: 13px;
	}

	.title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 51px;
		background: #ffffff;
		box-shadow: 0px 1px 0px 0px #f3f3f3;
		border-bottom: 2px solid #f3f3f3;

		.title_jl {
			font-size: 14px;
			font-weight: 400;
			color: #414a60;
			margin-left: 30px;
		}

		.title_yl {
			font-size: 14px;
			font-weight: 400;
			color: #126bf9;
			margin-right: 30px;
		}
	}

	.active {
		background: #ffffff;
		overflow: hidden;

		.activeText {
			margin: 17px 0px 30px;

			.activeName {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.nameleft {
					font-size: 24px;
					font-weight: 400;
					color: #24272e;
				}

				.nameimg {
					border-radius: 50%;
					width: 56px;
					height: 56px;
					overflow: hidden;
				}
			}

			.activeExperience {
				display: flex;
				align-items: center;
				flex-wrap: wrap;

				.active_xt {
					width: 2px;
					height: 12px;
					background: #e0e0e0;
					margin: 0 14px 0 21px;
				}

				.active_jingyan {
					display: flex;
					align-items: center;

					.experience_icon {
						width: 14px;
						height: 14px;
					}

					.experience {
						margin-left: 10px;
						font-size: 14px;
						font-weight: 400;
						color: #414a60;
					}
				}
			}

			.addition {
				margin: 50px 0 0 0;

				.addition_top {
					display: flex;
					align-items: center;
					justify-content: space-between;

					.top_left {
						font-size: 18px;
						font-weight: 400;
						color: #414a60;
					}

					.top_right {
						display: flex;
						align-items: center;

						.right_blue {
							width: 13px;
							height: 13px;
							background-color: #126bf9;
						}

						.right_tianjia {
							font-size: 13px;
							font-weight: 400;
							color: #126bf9;
							margin-left: 10px;
						}
					}
				}

				.addition_up {
					margin-top: 30px;
					font-size: 14px;
					font-weight: 400;
					color: #aeb1bb;
				}

				.addition_up-one {
					margin-top: 28px;

					.addition_up-one-title {
						margin: 20px 0;
					}

					.addition_up-one-bottom {
						>div:first-child {
							font-size: 15px;
						}

						.workContent-one {
							line-height: 23px;
							font-size: 14px;
						}
					}
				}
			}
		}
	}

	.activeTextr {
		padding: 0 30px;
	}

	.active_bianji {
		padding: 28px 30px 25px;
	}

	.redact {
		margin-top: 50px;
		width: 100%;
		background: #f8f9fb;
	}

	// 每个页面编辑不同的CSS
	.bianju_qzyx {
		font-size: 16px;
		font-weight: 400;
		color: #414a60;
	}

	.el-add-bianji {
		width: 90px;
		height: 36px;
		font-size: 14px;
		font-weight: 400;
		color: #ffffff;
		margin-top: 30px;
		margin-right: 20px;
	}

	.el-right_wc {
		width: 90px;
		height: 36px;
		font-size: 14px;
		font-weight: 400;
		color: #ffffff;
		margin-top: 30px;
		margin-right: 177px;
	}

	.bianju_qzxz {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 24px;

		.qzxz_left {
			.left_qwzw {
				font-size: 14px;
				font-weight: 400;
				color: #414a60;
			}

			.el-left_xzqw {
				width: 177px;
			}

			.left_gzdq {
				width: 313px;
				margin-top: 12px;
			}
		}

		.qzxz_right {
			margin-right: 177px;

			.right_xzyq {
				font-size: 14px;
				font-weight: 400;
				color: #414a60;
			}

			.right_xinzi {
				font-size: 14px;
				font-weight: 400;
				color: #414a60;
				margin: 10px 10px 0;
			}

			.el-xzyq_fw {
				width: 141px;
			}

			.el-right_qz {
				margin-top: 28px;
				width: 147px;
				height: 38px;
				background: #ffffff;
				border: 1px solid #e3e7ed;
				font-size: 14px;
				font-weight: 400;
				color: #414a60;
				text-align: center;
				line-height: 38px;
			}

			.right_qs {
				background: #e8f0f7;
				border: 1px solid #0f84fc;
				color: #126bf9;
			}

			.el-right_qz:nth-child(2) {
				margin-left: 20px;
			}

			.right_qx {
				width: 92px;
				height: 36px;
				background: #ffffff;
				border: 1px solid #e3e7ed;
				font-size: 14px;
				font-weight: 400;
				color: #414a60;
				text-align: center;
				line-height: 36px;
			}

			.right_wc {
				width: 92px;
				height: 36px;
				background: #126bf9;
				border: 1px solid #e3e7ed;
				font-size: 14px;
				font-weight: 400;
				color: #ffffff;
				text-align: center;
				line-height: 36px;
				margin-left: 20px;
			}
		}
	}

	.addition_ups {
		margin-top: 30px;
		font-size: 14px;
		font-weight: 400;
		color: #414a60;
	}

	.addition_ups-one {
		margin-top: 28px;

		.addition_ups-one-item {
			margin-bottom: 20px;
		}

		.addition_ups-one-item:last-child {
			margin-bottom: 0;
		}

		.addition_ups-one-heard {
			font-size: 15px;

			div:last-child {
				font-weight: 400;
				color: #9fa3b0;
				font-size: 13px;
				margin-left: 20px;
			}
		}

		.addition_ups-one-title {
			font-size: 15px;
			margin: 20px 0px;
		}

		.workContent {
			line-height: 23px;
		}
	}
</style>
